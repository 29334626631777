import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Logo from "../../../1.resources/3.files/images/logo.png"
import Links from "./links";


const Header = ({ }) => {

    useEffect(() => {
    }, [])
    return (
        <div style={{}} className="flex justify-center pt-6">
            <div className="block md:flex justify-between items-center w-full lg:w-[1280px] mx-10 md:px-0 " style={{ zIndex: 1000000 }}>
                <div className="md:flex items-center gap-x-8">
                    <a href="/" className="flex justify-center md:justify-start items-center gap-x-4">
                        <p className="text-2xl text-white">WEWE</p>
                    </a>
                    <div className="flex items-center justify-center md:justify-start gap-x-6 mt-2 md:mt-0">
                        {/* <a href={window.location.pathname == "/" ? "#about" : "/#about"} className="flex items-center gap-x-2">
                            <p className="text-lg text-white">About</p>
                        </a> */}
                        <a href="/staking" className="flex items-center gap-x-2">
                            <p className="text-lg text-white">Staking</p>
                        </a>

                        {/* <a href="/delegates" className="flex items-center gap-x-2">
                            <p className="text-lg text-white">Delegates</p>
                        </a> */}
                    </div>
                </div>
                <div className="flex justify-center sm:block mt-6 sm:mt-0">
                    <a href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x1e917e764BC34d3BC313fe8159a6bD9d9FFD450d" target="_blank" className="bg-main px-4 py-2 w-fit rounded-full flex items-center gap-x-2 whitespace-nowrap">
                        <p className="text-white">Buy now</p>
                        <FontAwesomeIcon icon={['fas', 'arrow-right']} className="text-white text-sm" />
                    </a>
                </div>
            </div >
        </div >
    );

}


export default Header;
