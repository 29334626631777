import React, { useState, useContext, useEffect } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Hero from "./partials/hero";
import About from "./partials/about";
import BgImg from "../../1.resources/3.files/images/bg2.png";
import Footer from "../0.global/footer/footer";
import { ethers } from "ethers";
import Twitter from "./partials/twitter";

const Home = ({ walletConnected, setWalletConnected }) => {


    return (
        <div >
            <Hero />
            <About />
            <Twitter />
        </div>
    );

}

export default Home;
