import React, { useState, useContext, useEffect } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BgImg from "../../1.resources/3.files/images/bg2.png";
import Footer from "../0.global/footer/footer";
import { useAccount, useSigner } from "wagmi";
import { ConnectWallet } from "../0.global/wallet/connectWallet";
import { getWnsDomain } from "../../1.resources/2.js/0.global/3.api/callW3Api";
import { shortenaddress } from "../../1.resources/2.js/0.global/0.smallfunctions/global";
import CloudContracts from "../../1.resources/2.js/0.global/2.contracts/cloudContracts";
import { calculateZeroes } from "../../1.resources/2.js/0.global/0.smallfunctions/currencyConversion";
import StakeModal from "./partials/stake";
import { timeToString } from "../../1.resources/2.js/0.global/0.smallfunctions/time";
import { Variables } from "../../1.resources/2.js/0.global/2.contracts/variables";
import { ethers } from 'ethers'
import { getCloudProvider } from "../../1.resources/2.js/0.global/2.contracts/cloudProvider";

const Staking = ({ walletConnected, setWalletConnected }) => {
    let { address } = useAccount();
    const [domain, setDomain] = useState("");
    const [activeStakes, setActiveStakes] = useState([]);
    const [stakeModal, setStakeModal] = useState(false);

    async function init() {
        let result = await getWnsDomain(address);
        console.log(result);
        if (result != "null") {
            setDomain(result);
        }

    }

    async function checkStakes() {
        let total = await CloudContracts("eth", "main", "full").weweStakingContract.getStakeIDs(address);
        console.log(total);
        setActiveStakes(total);
    }

    useEffect(() => {
        console.log(address);
        if (address != null && address != "" && address != undefined) {
            init();
            checkStakes();
        }
    }, [address])

    useEffect(() => {
        document.title = "Staking - $WEWE";
    }, [])

    async function stake() {


    }

    return (
        <div id="about" className="min-w-screen flex justify-center items-center pb-20 pt-10">

            <div className="w-full lg:w-[1280px] bg-white px-10 lg:px-20 py-20 lg:rounded-xl basic">
                <p className="text-3xl font-bold">$WEWE STAKING</p>
                <p className="pt-4 text-lg">5% of $WEWE tokens are allocated for staking, providing users with an exciting opportunity to earn rewards for holding their tokens. </p>
                <p className="pt-4 text-lg">$WEWE encourages users to become active participants in the network and support the growth of the project. Staking $WEWE tokens not only provides users with the opportunity to earn rewards but also helps to increase the security and stability of the network.</p>


                <div className="mt-8 border-t-2 border-gray-200 pt-8">
                    {address == null ? (
                        <div>
                            <p className="text-lg">Connect your wallet to stake your $WEWE.</p>
                            <div className="mt-4">
                                <ConnectWallet />
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="block md:flex justify-between items-center border-b-2 border-gray-200 pb-6">
                                <div>
                                    <p className="text-xl font-semibold">Welcome {address != null ? domain == "" ? shortenaddress(address) : domain : ""}!</p>
                                    <p className="text-gray-500 text-sm pt-1">Use this portal to stake your $WEWE</p>
                                </div>
                                <div className="mt-4 md:mt-0">
                                    <ConnectWallet />
                                </div>
                            </div>

                            <div className="pt-8">
                                <p className="text-md  font-bold">Current stakes ({activeStakes.length})</p>
                            </div>

                            {activeStakes.length == 0 ? (
                                <div>
                                    <p className="text-md text-gray-500 pt-4">You have no active stakes</p>
                                </div>
                            ) : (
                                <div>
                                    {activeStakes.map((stake) => {
                                        return (
                                            <Stake stakeId={stake} />
                                        )
                                    })}
                                </div>
                            )}


                            <div className="pt-8">
                                <div className="bg-main px-4 py-2 rounded-full w-fit flex items-center gap-x-2" onClick={() => setStakeModal(true)}>
                                    <p className="text-white">{activeStakes.length == 0 ? "Stake" : "Stake more"}</p>
                                    <FontAwesomeIcon icon={['fas', 'arrow-right']} className="text-sm text-white" />
                                </div>
                            </div>
                        </div>)}
                </div>

                {address != null ? (
                    <StakeModal modalOpen={stakeModal} setModalOpen={setStakeModal} />

                ) : (null)}
            </div>


        </div>
    );

}

export default Staking;

const Stake = ({ stakeId }) => {
    let { address } = useAccount();
    const { data: signer } = useSigner()

    const [stakeDetails, setStakeDetails] = useState(null);
    const [errorMsg, setErrorMsg] = useState("");
    const [loadingMsg, setLoadingMsg] = useState("");

    async function init() {
        let result = await CloudContracts("eth", "main", "full").weweStakingContract.getStakeOfUserById(address, stakeId);
        console.log(result);
        setStakeDetails(result);
    }

    useEffect(() => {
        init()
    }, [])

    async function claim() {
        try {

            setLoadingMsg("Confirm the transaction in your wallet...");
            const weweStakingContract = new ethers.Contract(Variables().weweStakingAddr, Variables().weweStakingAbi, signer);
            let tx = await weweStakingContract.claim(stakeId);
            setLoadingMsg("Waiting for transaction to confirm...");
            getCloudProvider("eth", process.env.REACT_APP_NETWORK, "full").waitForTransaction(tx.hash, 1, 300000).then(async (receipt) => {
                window.location.reload();
            });
        } catch (e) {
            console.log();
            setLoadingMsg("")
            if (e.message.includes("insufficient funds")) {
                setErrorMsg("Insufficient funds.")
            } else {
                setErrorMsg("Something went wrong.")
            }
        }
    }

    return (
        <div className="mt-4 bg-gray-100 border-2 border-gray-200 rounded-2xl px-10 py-4">
            {stakeDetails ? (
                <div className="flex justify-between items-center">
                    <div>
                        <p className="text-md font-bold">{"#" + shortenaddress(stakeId)}</p>
                        <p className="text-sm text-gray-500 pt-1">{"Staked amount: " + ((stakeDetails.stakedAmount / 1e18).toFixed(2)).toLocaleString() + " WEWE"}</p>
                        <p className="text-sm text-gray-500 pt-1">{"Reward: " + ((stakeDetails.rewardAllocation / 1e18).toFixed(2)).toLocaleString()} WEWE</p>

                        {/* <p className="text-md text-gray-500 pt-1">{"Staked " + calculateZeroes(stake.amount, 18) + " $WEWE"}</p> */}

                        <div className='w-full mt-0' >
                            <div className='flex justify-center items-center gap-x-2 pt-4' style={{ display: loadingMsg == "" ? "none" : "flex" }}>
                                <FontAwesomeIcon icon={['fas', 'fa-circle-notch']} className="text-main" spin />
                                <p className='text-sm text-main font-bold'>{loadingMsg}</p>
                            </div>
                            <div className='flex justify-center items-center gap-x-2 pt-4' style={{ display: errorMsg == "" ? "none" : "flex" }}>
                                <FontAwesomeIcon icon={['fas', 'fa-info-circle']} className="text-amber-500" />
                                <p className='text-sm text-amber-500 font-bold'>{errorMsg}</p>
                            </div>
                        </div>
                    </div>
                    {Date.now() < (parseFloat((stakeDetails?.unlockTime).toString()) * 1000) ? (
                        <div>
                            <p className="text-blue-500 font-bold text-sm">unlocks {timeToString(parseFloat((stakeDetails?.unlockTime).toString()) * 1000)}</p>
                        </div>
                    ) : (
                        <div className="bg-main px-4 py-2 rounded-full w-fit flex items-center gap-x-2" onClick={() => claim()}>
                            <p className="text-white text-sm">{"Claim"}</p>
                            <FontAwesomeIcon icon={['fas', 'arrow-right']} className="text-sm text-white" />
                        </div>
                    )
                    }


                </div >
            ) : (null)}

        </div >
    )
}