import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'
import { useBalance } from 'wagmi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDisconnect } from 'wagmi'
import { colors } from '../../../1.resources/1.css/colors'
import { tokenomics } from '../../0.global/tokenomics'
import { formatinusd, getWewePrice } from '../../../1.resources/2.js/0.global/0.smallfunctions/currencyConversion'
import CloudContracts from '../../../1.resources/2.js/0.global/2.contracts/cloudContracts'
import { ethers } from 'ethers'
import { Variables } from '../../../1.resources/2.js/0.global/2.contracts/variables'
import { useAccount, useConnect, useSigner } from 'wagmi'
import { getCloudProvider } from '../../../1.resources/2.js/0.global/2.contracts/cloudProvider'
import { timeToString } from '../../../1.resources/2.js/0.global/0.smallfunctions/time'

const StakeModal = ({ modalOpen, setModalOpen }) => {
    const { address, isConnecting, isDisconnected } = useAccount()
    const { data: signer } = useSigner()
    const { data } = useBalance({ address: address })
    const [input, setInput] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [loadingMsg, setLoadingMsg] = useState("");
    const [loading, setLoading] = useState(false);


    const [weweBalance, setWeweBalance] = useState(0);
    const [weweBalanceUsd, setWeweBalanceUsd] = useState(formatinusd(0));
    const [reward, setReward] = useState(0);
    const [rewardInUsd, setRewardInUsd] = useState(formatinusd(0));
    const [apySelected, setApySelected] = useState(3);
    const [apyPercent, setApyPercent] = useState(0);
    const [lockingDuration, setLockingDuration] = useState([0, 0, 0, 0]);
    const [allowanceSet, setAllowanceSet] = useState(false);

    function closeModal() {
        setModalOpen(false)
    }

    function changeInput(e) {
        setErrorMsg("");
        setLoadingMsg("");
        //check if value is not negative
        if (e.target.value < 0 || e.target.value == "") {
            setInput("");
        } else {
            setInput(e.target.value)
        }
    }

    async function stake() {
        if (input > weweBalance || input == "" || isNaN(input)) {
            if (input == "" || isNaN(input)) {
                setErrorMsg("Please enter a valid amount");
            } else if (input > data?.formatted) {
                setErrorMsg("Entered amount is greater than your balance.");
            }
        } else {
            try {

                setLoadingMsg("Confirm the transaction in your wallet...");
                const weweStakingContract = new ethers.Contract(Variables().weweStakingAddr, Variables().weweStakingAbi, signer);
                let tx = await weweStakingContract.stake(input, apySelected);
                setLoadingMsg("Waiting for transaction to confirm...");
                getCloudProvider("eth", process.env.REACT_APP_NETWORK, "full").waitForTransaction(tx.hash, 1, 300000).then(async (receipt) => {
                    window.location.reload();
                });
            } catch (e) {
                console.log();
                setLoadingMsg("")
                if (e.message.includes("insufficient funds")) {
                    setErrorMsg("Insufficient funds.")
                } else {
                    setErrorMsg("Something went wrong.")
                }
            }
        }
    }


    async function init() {
        let balance = await CloudContracts("eth", process.env.REACT_APP_NETWORK, "full").weweContract.balanceOf(address);
        balance = parseFloat(balance.toString()) / 10 ** 18;
        setWeweBalance(Math.floor(balance));
        setWeweBalanceUsd(formatinusd(parseFloat((balance * await getWewePrice()))));
        let apys = await Promise.all(
            [
                CloudContracts("eth", process.env.REACT_APP_NETWORK, "full").weweStakingContract.calculateApy(100, 0),
                CloudContracts("eth", process.env.REACT_APP_NETWORK, "full").weweStakingContract.calculateApy(100, 1),
                CloudContracts("eth", process.env.REACT_APP_NETWORK, "full").weweStakingContract.calculateApy(100, 2),
                CloudContracts("eth", process.env.REACT_APP_NETWORK, "full").weweStakingContract.calculateApy(100, 3),
            ]
        )
        apys = apys.map((apy) => {
            return parseFloat(apy.toString()) / 100;
        })
        console.log(apys);
        setApyPercent(apys);

        let durations = await Promise.all(
            [
                CloudContracts("eth", process.env.REACT_APP_NETWORK, "full").weweStakingContract.getLockingDuration(0),
                CloudContracts("eth", process.env.REACT_APP_NETWORK, "full").weweStakingContract.getLockingDuration(1),
                CloudContracts("eth", process.env.REACT_APP_NETWORK, "full").weweStakingContract.getLockingDuration(2),
                CloudContracts("eth", process.env.REACT_APP_NETWORK, "full").weweStakingContract.getLockingDuration(3),
            ]
        )
        durations = durations.map((duration) => {
            return parseInt(duration.toString());
        }
        )
        setLockingDuration(durations);
    }
    useEffect(() => {
        init()
    }, [])

    async function setAllowance() {
        try {
            setLoadingMsg("Confirm the transaction in your wallet...");
            const weweContract = new ethers.Contract(Variables().weweAddr, Variables().weweAbi, signer);
            let tx = await weweContract.approve(Variables().weweStakingAddr, ethers.constants.MaxUint256);
            setLoadingMsg("Waiting for transaction to confirm...");
            getCloudProvider("eth", process.env.REACT_APP_NETWORK, "full").waitForTransaction(tx.hash, 1, 300000).then(async (receipt) => {
                checkAllowance(input == "" ? 0 : input);
                setLoadingMsg("");
            });

        } catch (e) {
            console.log();
            setLoadingMsg("")
            if (e.message.includes("insufficient funds")) {
                setErrorMsg("Insufficient funds.")
            } else {
                setErrorMsg("Something went wrong.")
            }
        }
    }

    async function checkAllowance(value) {
        let allowance = await CloudContracts("eth", process.env.REACT_APP_NETWORK, "full").weweContract.allowance(address, Variables().weweStakingAddr);
        allowance = parseFloat(allowance.toString()) / 10 ** 18;
        if (allowance >= value) {
            setAllowanceSet(true);
        } else {
            setAllowanceSet(false);
        }
    }
    async function setRewardAmount() {
        setLoading(true)
        let rewardAmount = await CloudContracts("eth", process.env.REACT_APP_NETWORK, "full").weweStakingContract.calculateRewards(input == "" ? 0 : input, apySelected);
        rewardAmount = parseFloat(rewardAmount.toString()) / 1e18;
        setReward(rewardAmount);
        setRewardInUsd(formatinusd(parseFloat((rewardAmount * await getWewePrice()))));

        await checkAllowance(input == "" ? 0 : input);
        setLoading(false)
    }
    useEffect(() => {
        setRewardAmount();
    }, [apySelected, input])

    return (
        <>
            <Transition appear show={modalOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-60" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all" style={{ zIndex: 1000000000000000000000 }}>

                                    <div className='px-4'>
                                        <div className='flex flex-col items-start -mt-4 pb-8'>
                                            <div className='flex justify-between items-center w-full pt-6 border-b-2 borde-gray-200 pb-4'>
                                                <div>
                                                    <p className='text-2xl font-bold'>$WEWE Stake</p>
                                                    <p className='text-sm text-gray-500'>Stake your $WEWE</p>
                                                </div>
                                                <div className='flex justify-end'>
                                                    <div className='w-6 h-6 bg-[#E6E6E6] rounded-full flex justify-center items-center cursor-pointer' onClick={() => setModalOpen(false)}>
                                                        <FontAwesomeIcon icon={['fas', 'fa-xmark']} className="" style={{ color: '#5E6363', fontSize: "80%" }} />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='w-full'>

                                                <div className='mt-4'>
                                                    <div className='flex justify-between items-center'>

                                                        <p className='text-sm text-gray-500'>Staking amount</p>
                                                        <p className='text-sm text-blue-500 font-bold cursor-pointer' onClick={() => setInput(weweBalance)}>Max</p>
                                                    </div>
                                                    {/* <input className='w-full bg-[#F5F5F5] rounded-full px-4 py-2 mt-2' placeholder='0.00 ETH' /> */}
                                                    <div className='flex items-center bg-gray-100 rounded-xl border-2 border-gray-200 mt-2'>
                                                        <input id="input" className='w-full bg-transparent px-4 py-2 outline-none font-bold' placeholder='0.00' type='number' value={input} onChange={changeInput} onKeyDown={(e) => e.key == "Enter" ? stake() : null} />

                                                        <div className='px-4'>
                                                            <p className='font-bold'>WEWE</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex justify-between items-center mt-8'>
                                                    <p className='text-sm text-gray-500'>Your balance</p>
                                                    <div>
                                                        <p className='text-sm text-gray-500 text-right'>{weweBalance.toLocaleString()} WEWE</p>
                                                        <p className='text-xs text-gray-500 text-right'>({weweBalanceUsd})</p>

                                                    </div>
                                                </div>
                                                <div className='mt-8'>
                                                    <p className='text-sm text-gray-500'>Select time period</p>

                                                    <div className='flex items-center mt-4 border-2 border-gray-200 rounded-xl'>
                                                        <div className={`w-3/12 flex justify-center items-center py-6 cursor-pointer border-r-2 border-gray-200 rounded-l-xl ${apySelected == 0 ? "bg-blue-500" : ""}`} onClick={() => setApySelected(0)}>
                                                            <p className={`font-bold ${apySelected == 0 ? "text-white" : ""}`}>3 days</p>
                                                        </div>
                                                        <div className={`w-3/12 flex justify-center items-center py-6 cursor-pointer border-r-2 border-gray-200 ${apySelected == 1 ? "bg-blue-500" : ""}`} onClick={() => setApySelected(1)}>
                                                            <p className={`font-bold ${apySelected == 1 ? "text-white" : ""}`}>15 days</p>

                                                        </div>
                                                        <div className={`w-3/12 flex justify-center items-center py-6 cursor-pointer border-r-2 border-gray-200 ${apySelected == 2 ? "bg-blue-500" : ""}`} onClick={() => setApySelected(2)}>
                                                            <p className={`font-bold ${apySelected == 2 ? "text-white" : ""}`}>1 month</p>

                                                        </div>
                                                        <div className={`w-3/12 flex justify-center items-center py-6 cursor-pointer rounded-r-xl ${apySelected == 3 ? "bg-blue-500" : ""}`} onClick={() => setApySelected(3)}>
                                                            <p className={`font-bold ${apySelected == 3 ? "text-white" : ""}`}>3 months</p>

                                                        </div>
                                                    </div>
                                                </div>




                                                <div className='mt-8 bg-blue-100 px-4 py-4 rounded-xl border-2 border-blue-200'>
                                                    <div className='flex justify-between items-center'>
                                                        <p className='text-sm text-blue-500 font-bold'>Your reward</p>
                                                        {loading ? (
                                                            <FontAwesomeIcon icon={['fas', 'fa-circle-notch']} className='text-blue-500 text-sm text-right' spin />
                                                        ) : (
                                                            <div>
                                                                <p className='text-sm text-blue-500 font-bold text-right'>{isNaN(parseFloat(reward)) ? "0.00" : parseFloat(reward) > 1 ? reward.toFixed(0) : parseFloat(reward).toFixed(4)} WEWE</p>
                                                                <p className='text-xs text-blue-500 font-bold text-right'>({rewardInUsd})</p>
                                                            </div>
                                                        )}

                                                    </div>

                                                    <div className='flex justify-between items-center mt-4'>
                                                        <p className='text-sm text-blue-500 font-bold'>Your APY</p>
                                                        <p className='text-sm text-blue-500 font-bold'>{apyPercent[apySelected]}%</p>
                                                    </div>

                                                    <div className='flex justify-between items-center mt-4'>
                                                        <p className='text-sm text-blue-500 font-bold'>Unlock date</p>
                                                        <p className='text-sm text-blue-500 font-bold'>{timeToString(Date.now() + (lockingDuration[apySelected] * 1000))}</p>
                                                    </div>

                                                    <p className='text-xs text-gray-500 text-center mt-4'>Your tokens will be locked for the time period you select. Once time period ends, you will be able to unlock your tokens and redeem the calculated rewards.</p>

                                                </div>

                                            </div>
                                        </div>

                                        <div className='w-full ' >
                                            <div className='flex justify-center items-center gap-x-2 pb-4' style={{ display: loadingMsg == "" ? "none" : "flex" }}>
                                                <FontAwesomeIcon icon={['fas', 'fa-circle-notch']} className="text-main" spin />
                                                <p className='text-sm text-main font-bold'>{loadingMsg}</p>
                                            </div>
                                            <div className='flex justify-center items-center gap-x-2 pb-4' style={{ display: errorMsg == "" ? "none" : "flex" }}>
                                                <FontAwesomeIcon icon={['fas', 'fa-info-circle']} className="text-amber-500" />
                                                <p className='text-sm text-amber-500 font-bold'>{errorMsg}</p>
                                            </div>
                                        </div>
                                        <div className='w-full' >
                                            {loading ? (
                                                <div className='w-full flex justify-center items-center bg-main m-1 py-4 rounded-full cursor-pointer gap-x-3'>
                                                    <FontAwesomeIcon icon={['fas', 'fa-circle-notch']} className='text-white text-lg' spin />
                                                </div>
                                            ) : (
                                                <div className='w-full flex justify-center items-center bg-main m-1 py-4 rounded-full cursor-pointer gap-x-3' onClick={() => allowanceSet ? stake() : setAllowance()}>
                                                    <p className='text-md text-white'>{allowanceSet ? "Stake" : "Approve WEWE"}</p>
                                                    <FontAwesomeIcon icon={['fas', 'fa-arrow-right']} className="" style={{ color: '#fff', fontSize: "100%" }} />
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition >
        </>
    )

}

export default StakeModal;