import React, { useState, useEffect, useContext } from "react";
import Home from "./2.views/1.home/home";
import Header from "./2.views/0.global/header/header";
import Footer from "./2.views/0.global/footer/footer";
import Links from "./2.views/5.links/links";
import Staking from "./2.views/6.staking/staking";

class Main extends React.Component {


  render() {
    return (
      <div style={{ backgroundColor: "#000" }} className="min-h-screen">
        {window.location.pathname == "/links" ? <Links /> : (
          <div className="flex flex-col justify-between min-h-screen">
            <div>
              {window.location.pathname == "/" ? null : <Header />}
            </div>

            <div>
              {window.location.pathname == "/" ? <Home /> : null}
              {window.location.pathname == "/staking" ? <Staking /> : null}
            </div>
            <Footer />
          </div>
        )}
      </div >
    );
  }
}

export default Main;


