import React, { useState, useContext } from "react";
import BgImage from "../../../1.resources/3.files/images/4.png";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Header from "../../0.global/header/header";


const Hero = ({ }) => {

    return (
        <div className="min-w-screen min-h-screen flex flex-col">
            <div className="flex-none">
                <Header />
            </div>
            <div className="grow flex justify-center items-center">
                <div className="flex justify-center items-center">
                    <div>

                        <div className="flex justify-center items-center">
                            <img src={BgImage} className="w-10/12 h-10/12 sm:w-8/12 sm:h-8/12 lg:w-4/12 lg:h-4/12" />
                        </div>

                        {/* <p className="text-white text-3xl font-bold pt-4 text-center">WEWE</p>
                        <p className="text-white text-center pt-2 text-xl">Your friendly neighbourhood doggo!</p> */}

                        {/* <div className="flex justify-center items-center pt-8">
                            <div className="bg-white px-4 py-2 w-fit rounded-full flex items-center gap-x-2">
                                <p className="text-main">Buy now</p>
                                <FontAwesomeIcon icon={['fas', 'chevron-right']} className="text-main text-sm" />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>


            <div className="flex-none flex justify-center pb-10">
                <div>
                    <p className="text-white">Scroll down</p>
                    <div className="flex justify-center items-center">
                        <FontAwesomeIcon icon={['fas', 'chevron-down']} className="text-white text-lg pt-6 animate-bounce" />
                    </div>
                </div>
            </div>


        </div>
    );

}

export default Hero;
