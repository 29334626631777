import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LinksJSON } from "../../0.global/header/links";
import { tokenomics } from "../../0.global/tokenomics";
import { formatinusd } from "../../../1.resources/2.js/0.global/0.smallfunctions/currencyConversion";
import { timeToString } from "../../../1.resources/2.js/0.global/0.smallfunctions/time";


const About = ({ }) => {
    const endTime = 1684436400;
    useEffect(() => {
        console.log(Date.now())
    }, [])
    return (
        <div id="about" className="min-w-screen min-h-screen flex justify-center items-center pb-20 pt-10">

            <div className="w-full lg:w-[1280px] bg-white px-10 lg:px-20 py-20 lg:rounded-xl basic">
                <div>
                    <p className="text-3xl">What is $WEWE?</p>
                    <p className="pt-4 text-lg">Ah, Wewe! The legendary golden-retriever-turned-memecoin phenomenon that has taken the world by storm! What is Wewe, you ask? Well, imagine a magical fusion of fluffy cuteness, internet culture, and financial intrigue. That's right, Wewe is not just your ordinary digital currency; it's a bark-tastic adventure in the world of cryptocurrencies.</p>
                    <p className="pt-4 text-lg">After Doges and Shiba-Inus, it's WEWE's time to shine!</p>
                </div>

                <div className="pt-16">
                    <p className="text-3xl">How to buy?</p>

                    <div className="pt-4">
                        <a href={LinksJSON.uniswap} target="_blank" className="flex items-center gap-x-2 mt-4 bg-main px-4 py-2 rounded-full w-fit">
                            <p className="text-white">Buy on Uniswap</p>
                            <FontAwesomeIcon icon={['fas', 'arrow-right']} className="text-lg text-white" />
                        </a>


                        <div className="pt-8">
                            <Links text={"Bitmart"} link={"https://www.bitmart.com/"} />
                            <Links text={"LBANK"} link={"https://www.lbank.info/"} />
                            <Links text={"Poloniex"} link={"https://poloniex.com/"} />
                            <Links text={"Bilaxy"} link={"https://t.co/yhA1IFu3Lz"} />
                            <Links text={"MEXC"} link={"https://www.mexc.com/"} />
                            <Links text={"CoinW (Launching Soon)"} link={"https://www.coinw.com/"} />

                        </div>
                        <p className="text-md pt-8 ">Contract: <span className="break-all">0x1e917e764BC34d3BC313fe8159a6bD9d9FFD450d</span></p>

                    </div>
                </div>

                <div className="pt-16">
                    <p className="text-3xl">Links?</p>
                    <div className="pt-4">
                        <Links text={"Twitter"} link={LinksJSON.twitter} />
                        <Links text={"Telegram"} link={LinksJSON.telegram} />
                        <Links text={"Telegram CN"} link={"https://t.me/WEWEofficialCn"} />
                        <Links text={"Discord"} link={LinksJSON.discord} />
                        <Links text={"Etherscan"} link={LinksJSON.etherscan} />
                        <Links text={"CoinMarketCap"} link={LinksJSON.cmc} />
                        <Links text={"Uniswap"} link={LinksJSON.uniswap} />

                    </div>
                </div>

                <div className="pt-16">
                    <p className="text-3xl">Tokenomics?</p>
                    <p className="pt-4 text-lg">Tokens will be allocated according to the following tiers:</p>
                    <div className="pt-4">
                        <Chart />
                    </div>
                    <p className="pt-6 font-bold">TOTAL SUPPLY: {formatinusd(tokenomics.totalSupply).slice(1, 16)}</p>

                    <div className="pt-4">

                        <Points text="Tokens allocated for presale will be available for everyone who participate in the presale." />
                        <Points text="Tokens allocated for vault will be locked away for safekeeping." />
                        <Points text="Tokens allocated for marketing and development will be used for the growth of $WEWE ecosystem." />
                        <Points text="Tokens allocated for airdrops will be used for airdrop raffles across $WEWE social media." />
                        <Points text="Tokens allocated for $WEWE Team will be distributed to team members of $WEWE." />

                    </div>
                </div>

                <div className="pt-16">
                    <p className="text-3xl">Presale?</p>
                    <p className="pt-4 text-lg">The $WEWE memecoin will hold a presale exclusively for early contributors. This presale is an opportunity for contributors to invest in $WEWE before it hits the market. During the presale, participants can contribute any amount between 0.01 ETH to 5 ETH. All proceeds from the presale will go towards funding the liquidity pool for the $WEWE token.</p>
                    <p className="pt-4 text-lg">After the $WEWE token launches, presale contributors will be able to claim a percentage of the tokens allocated for presale based on their contribution ratio. This allows presale contributors to benefit from the early investment and support of the $WEWE token.</p>
                    <p className="pt-4 text-lg">Overall, the $WEWE presale is an exciting opportunity for contributors to get in on the ground floor of a promising memecoin and help support its growth and development.</p>

                    <div className="bg-gray-100 border-2 border-gray-200 px-10 py-6 rounded-xl mt-4">
                        <div>
                            <p className="text-lg font-bold">Presale has ended.</p>
                        </div>

                    </div>

                </div>

                <div className="pt-16">
                    <p className="text-3xl">Airdrops?</p>
                    <p className="pt-4 text-lg">W3 Labs has allocated 7.5% of tokens for airdrops, which will be distributed in two tiers. The first tier consists of 5% of tokens, which will be reserved exclusively for WNS (Web3 Name Service) holders. These tokens will be distributed weekly starting from one week post-launch. WNS holders will have the opportunity to receive 5% of tokens after the launch.</p>
                    <p className="pt-4 text-lg">The second tier comprises 2.5% of tokens, which will be allocated to social media giveaways. These giveaways will be an exciting opportunity for those who are not yet part of the $WEWE community to learn more about it and potentially receive tokens. The airdrop giveaways are an excellent way for users to support the project and earn rewards, while also promoting awareness of the $WEWE token.</p>
                    <a href="https://domains.w3.one/" target="_blank" className="flex items-center gap-x-2 mt-4 bg-main px-4 py-2 rounded-full w-fit">
                        <p className="text-white">Register WNS domain</p>
                        <FontAwesomeIcon icon={['fas', 'arrow-right']} className="text-lg text-white" />
                    </a>
                </div>

                <div className="pt-16">
                    <p className="text-3xl">Marketing and development?</p>
                    <p className="pt-4 text-lg">While $WEWE is first and foremost a memecoin designed for fun and laughter, that doesn't mean it can't evolve into something more. The tokens allocated for marketing and development are an essential component of this growth, as they will be used to fund the continued development and expansion of the $WEWE ecosystem.</p>
                    <p className="pt-4 text-lg">At W3, we believe that any interesting idea has the potential to become a reality. We're always open to new ideas that can help grow the $WEWE community. By staying open and adaptable, $WEWE can continue to evolve and grow alongside the changing cryptocurrency market. We're excited to see what the future holds for $WEWE.</p>

                </div>

                <div className="pt-16">
                    <p className="text-3xl">Staking?</p>
                    <p className="pt-4 text-lg">5% of $WEWE tokens will be allocated for staking, providing users with an exciting opportunity to earn rewards for holding their tokens. </p>
                    <p className="pt-4 text-lg">$WEWE encourages users to become active participants in the network and support the growth of the project. Staking $WEWE tokens not only provides users with the opportunity to earn rewards but also helps to increase the security and stability of the network.</p>
                    <a href="/staking" className="flex items-center gap-x-2 mt-4 bg-main px-4 py-2 rounded-full w-fit">
                        <p className="text-white">Stake now</p>
                        <FontAwesomeIcon icon={['fas', 'arrow-right']} className="text-lg text-white" />
                    </a>
                </div>

                <div className="pt-16">
                    <p className="text-3xl">$WEWE Delegates?</p>
                    <p className="pt-4 text-lg">$WEWE delegates will elevate the $WEWE community and generate widespread awareness about $WEWE on the influential platform of Twitter.</p>
                    <p className="pt-4 text-lg">A delegates fund equivalent to 5% of the total $WEWE supply will be allocated and securely locked in a smart contract. Starting from the launch of $WEWE, 1% of the tokens will be distributed to the delegates on a weekly basis for the first five weeks until all tokens have been dispersed.</p>

                </div>


            </div>

        </div>
    );

}

export default About;


const Links = ({ text, link }) => {

    return (
        <a href={link} target="_blank" className="flex items-center mt-2 gap-x-2">
            <div>
                <FontAwesomeIcon icon={['fas', 'circle']} className="text-main" style={{ width: 6.5 }} />
            </div>
            <div className="flex items-center gap-x-2">
                <p className="text-md text-main">{text}</p>
                <FontAwesomeIcon icon={['fas', 'chevron-right']} className="text-xs text-main" />
            </div>
        </a>
    )
}


const Points = ({ text }) => {

    return (
        <div className="flex mt-2 gap-x-4">
            <div>
                <FontAwesomeIcon icon={['fas', 'circle']} style={{ width: 6.5 }} />
            </div>
            <div>
                <p className="text-sm">{text}</p>
            </div>
        </div>
    )
}


const Chart = ({ }) => {

    return (
        <div>


            <div className="pt-4">
                <p>PRESALE - {tokenomics.presalePercent}%</p>
                <Bar percent={tokenomics.presalePercent} />
            </div>

            <div className="pt-4">
                <p>LIQUIDITY POOL - {tokenomics.publicPercent}%</p>
                <Bar percent={tokenomics.publicPercent} />
            </div>

            <div className="pt-4">
                <p>MARKETING - {tokenomics.marketingPercent}%</p>
                <Bar percent={tokenomics.marketingPercent} />
            </div>

            <div className="pt-4">
                <p>DEVELOPMENT - {tokenomics.developmentPercent}%</p>
                <Bar percent={tokenomics.developmentPercent} />
            </div>

            <div className="pt-4">
                <p>AIRDROPS - {tokenomics.airdropPercent}%</p>
                <Bar percent={tokenomics.airdropPercent} />
            </div>

            <div className="pt-4">
                <p>$WEWE DELEGATES - {tokenomics.delegatesPercent}%</p>
                <Bar percent={tokenomics.delegatesPercent} />
            </div>

            <div className="pt-4">
                <p>STAKING - {tokenomics.stakingPercent}%</p>
                <Bar percent={tokenomics.stakingPercent} />
            </div>

            <div className="pt-4">
                <p>$WEWE TEAM - {tokenomics.teamPercent}%</p>
                <Bar percent={tokenomics.teamPercent} />
            </div>

            {/* <div className="pt-4">
                <p>VAULT - {tokenomics.vaultPercent}%</p>
                <Bar percent={tokenomics.vaultPercent} />
            </div> */}





        </div>

    )
}

export const Bar = ({ percent }) => {


    return (
        <div className="bg-gray-100 rounded-full">
            <div className="h-8 bg-main rounded-full" style={{ width: `${percent}%` }}>

            </div>
        </div>

    )
}